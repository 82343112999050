<template>
  <ul class="day">
    <li
      :class="{
        act: signed >= index + li_index,
      }"
      :key="index"
      v-for="(item, index) in originData"
    >
      <div class="tip">
        <!-- <span v-if="item.type == 'lottery'"> 抽奖</span> -->
        <i
          v-if="
            item.type == 'voucher' ||
              item.type == 'goods' ||
              item.type == 'other' ||
              item.type == 'choose' ||
              item.type == 'random'
          "
        ></i>
        <span v-if="item.type == 'voucher'">
          {{ item.voucher.vouchertemplate_title }}</span
        >
        <span v-if="item.type == 'goods'"> {{ item.goods.goods_name }}</span>
        <span v-if="item.type == 'other'">惊喜</span>
        <span v-if="item.type == 'choose'">
          {{
            item.choose.length == 1 ? "礼包" : item.choose.length + "选1"
          }}</span
        >
        <span v-if="item.type == 'random'"> 随机赠</span>
      </div>
      <span>{{ index + li_index }}天</span>
      <template v-if="signed >= index + li_index">
        <svg-icon icon-class="signin_ed"></svg-icon>
      </template>
      <template v-else>
        <svg-icon
          icon-class="signin_b"
          v-if="item.type == 'lottery'"
        ></svg-icon>
        <svg-icon
          icon-class="signin_chooese"
          v-else-if="item.type == 'goods'"
        ></svg-icon>
        <svg-icon icon-class="signin_giftbag" v-else></svg-icon>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    originData: {},
    signed: {},
    li_index: {},
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.day {
  display: flex;
  .act {
    background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
    border-radius: 8px;
    opacity: 0.6;
    span {
      color: #ffffff;
    }
    b {
      color: rgba(255, 255, 255, 0.4);
    }
  }
  li {
    background: #eeeeee;
    border-radius: rem(8);
    font-size: rem(24);
    width: rem(83);
    height: rem(142);
    margin-right: rem(10);
    position: relative;
    z-index: 99;
    margin-top: rem(40);

    .tip {
      position: absolute;
      width: rem(83);
      height: rem(33);
      padding: rem(3);
      top: rem(-43);
      left: 0;
      span {
        display: block;
        color: #ffffff;
        font-weight: bold;
        background: #ff9e0b;
        border-radius: rem(2);
        height: rem(33);
        line-height: rem(33);
        font-size: rem(18);
        text-align: center;
        @include ell;
      }
      i {
        background: url("~@image/points/solid-bottom.png") no-repeat;
        background-size: 100%;
        position: absolute;
        width: rem(9);
        height: rem(3);
        top: rem(36);
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    &:last-of-type {
      margin-right: 0;
    }
    b {
      color: #989898;
    }
    span {
      color: #222222;
    }
    svg {
      width: rem(50);
      height: rem(50);
      margin: rem(6) 0;
    }
    display: flex;
    // flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
</style>
