var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"day"},_vm._l((_vm.originData),function(item,index){return _c('li',{key:index,class:{
      act: _vm.signed >= index + _vm.li_index,
    }},[_c('div',{staticClass:"tip"},[(
          item.type == 'voucher' ||
            item.type == 'goods' ||
            item.type == 'other' ||
            item.type == 'choose' ||
            item.type == 'random'
        )?_c('i'):_vm._e(),(item.type == 'voucher')?_c('span',[_vm._v(" "+_vm._s(item.voucher.vouchertemplate_title))]):_vm._e(),(item.type == 'goods')?_c('span',[_vm._v(" "+_vm._s(item.goods.goods_name))]):_vm._e(),(item.type == 'other')?_c('span',[_vm._v("惊喜")]):_vm._e(),(item.type == 'choose')?_c('span',[_vm._v(" "+_vm._s(item.choose.length == 1 ? "礼包" : item.choose.length + "选1"))]):_vm._e(),(item.type == 'random')?_c('span',[_vm._v(" 随机赠")]):_vm._e()]),_c('span',[_vm._v(_vm._s(index + _vm.li_index)+"天")]),(_vm.signed >= index + _vm.li_index)?[_c('svg-icon',{attrs:{"icon-class":"signin_ed"}})]:[(item.type == 'lottery')?_c('svg-icon',{attrs:{"icon-class":"signin_b"}}):(item.type == 'goods')?_c('svg-icon',{attrs:{"icon-class":"signin_chooese"}}):_c('svg-icon',{attrs:{"icon-class":"signin_giftbag"}})]],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }