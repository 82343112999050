var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"points"}},[_c('div',{staticClass:"p_info"},[_c('div',{staticClass:"p_sub"},[_c('span',[_vm._v("累计签到"),_c('i',[_vm._v(_vm._s(_vm.max_day)+"天")])]),_c('span',[_vm._v("赢惊喜礼包")])]),_c('div',{staticClass:"rule",on:{"click":function($event){return _vm.$router.push({
          name: 'signinRule',
          query: Object.assign({}, _vm.$route.query),
        })}}},[_vm._v(" 规则 ")]),_c('div',{staticClass:"reload",on:{"click":function($event){return _vm.$router.push({
          name: 'signinLog',
          query: {
            signin_id: _vm.$route.query.signin_id,
          },
        })}}},[_vm._v(" 奖励记录 ")])]),_c('div',{staticClass:"p_sign"},[(!_vm.activeStatus)?_c('h1',[_vm._v("已累计签到"+_vm._s(_vm.signined)+"天")]):_vm._e(),_c('div',{staticClass:"title"},[(_vm.activeStatus)?_c('h1',[_vm._v("已累计签到"+_vm._s(_vm.signined)+"天")]):_vm._e(),(_vm.activeStatus)?_c('div',[_vm._v(" 活动"),_c('i',[_vm._v(_vm._s(_vm.time.day))]),_vm._v("天"),_c('i',[_vm._v(_vm._s(_vm.time.min))]),_vm._v("分"),_c('i',[_vm._v(_vm._s(_vm.time.sec))]),_vm._v("秒后结束 ")]):_c('div',[_vm._v(" 活动将在"+_vm._s(_vm._f("time")(_vm.start_time))+" ~ "+_vm._s(_vm._f("time")(_vm.end_time))+"进行 ")])]),_c('div',[_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":0,"show-indicators":false,"loop":false}},_vm._l((_vm.dPointGoodsList),function(item,index){return _c('van-swipe-item',{key:index},[_c('oList',{attrs:{"originData":_vm.dPointGoodsList[index],"signed":_vm.signined,"li_index":index * 7 + 1}})],1)}),1)],1),(_vm.activeStatus)?_c('div',{staticClass:"sign-btn",class:{
        'sign-btn-act': _vm.signinStatus,
      },on:{"click":function($event){_vm.signinStatus ? '' : _vm.getSign()}}},[_vm._v(" "+_vm._s(_vm.signinStatus ? "已签到" : "签到领取")+" ")]):_vm._e(),(!_vm.activeStatus)?_c('div',{staticClass:"sign-btn sign-btn-act"},[_vm._v(" 不在活动范围内 ")]):_vm._e(),_c('div',{staticClass:"get_kefu",on:{"click":function($event){_vm.kefuBoo = true}}},[_vm._v(" 咨询客服 ")])]),_c('oSigned',{attrs:{"show":_vm.signedShow,"obj":_vm.thisSigninObj,"chooeseObj":_vm.thisChooeseItem},on:{"update:show":function($event){_vm.signedShow=$event}}}),_c('moModel',{attrs:{"moData":_vm.moData}}),_c('oChat',{attrs:{"boo":_vm.kefuBoo},on:{"update:boo":function($event){_vm.kefuBoo=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }